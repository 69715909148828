import { dataAccounting } from "../../../../../components/selectAccountingPlan";
import {
    b64Uri,
    cleanNumberInput,
    formatCurrency,
    getData,
    getElement,
    getJson,
    isNumber,
    resetForm,
    setSelect2Ajax,
} from "../../../../../helpers";

export class TaxModal extends HTMLDivElement {
    connectedCallback() {
        this.load();
    }

    load() {
        // console.log(this);
        const Form = getElement("[data-form=form]", this);

        Form.elements.save.addEventListener("click", () => {
            if (Form.reportValidity()) {
                Form.submit();
            }
        });

        Form.elements.type.addEventListener("input", (e) => {
            const Target = e.target;
            const Option = Target.options[Target.selectedIndex];
            const Labels = Form.querySelectorAll("[data-target]");

            Labels.forEach((item) => {
                item.textContent = getData(
                    Option,
                    getData(item, "data-target")
                );
            });
        });
    }

    static create(modal, title, url) {
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        dataAccounting.accountingplans = [];
        dataAccounting.concat = [];
    }

    static edit(modal, title, url, data) {
        data = getJson(b64Uri(data, "decode"));
        const Form = getElement("[data-form=form]", getElement(modal));
        resetForm(Form, title, url);
        Form.elements.type.value = data.type;
        Form.elements.tax.value = formatCurrency(data.tax, "decimal");
        Form.elements.surcharge.value = formatCurrency(
            data.surcharge,
            "decimal"
        );
        Form.elements.description.value = data.description;
        dataAccounting.concat = [];
        data.accountingplans.forEach((item) => {
            const T = Form.elements[`account[${item.pivot.type}]`];
            setSelect2Ajax(T, item.id, item.number + " - " + item.name);
            dataAccounting.concat[item.pivot.type] = item.id;
        });
        dataAccounting.accountingplans = Object.values(dataAccounting.concat);
    }
}

window.customElements.define("tax-modal", TaxModal, {
    extends: "div",
});
