class WithholdingSumaryModule extends HTMLElement {
    connectedCallback() {
        // this.load();
    }

    load() {
        console.log(this);
    }
}

window.customElements.define(
    "withholdingsumary-module",
    WithholdingSumaryModule,
    {
        extends: "section",
    }
);
