import {
    CSRF,
    bodyRequest,
    formatCurrency,
    getData,
    getElement,
    requestOptions,
    resetSelect2Ajax,
} from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class JournalModule extends HTMLElement {
    async connectedCallback() {
        //console.log(this);
        const DtElement = getElement("[data-table=dt]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);

        let project = "";
        let start = "";
        let end = "";

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
            pageLength: 50,
            ordering: false,
        };

        configDt.ajax = {
            url: getData("accountingJournalUrlDt"),
            type: "POST",
            data: function (data) {
                data.project = project;
                data.startDate = start;
                data.endDate = end;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };

        // Columnas del AJAX
        configDt.columns = [
            { data: "entry", className: "text-center" },
            { data: "date" },
            { data: "account" },
            { data: "description" },
            { data: "project" },
            { data: "debit", width: "7%", className: "text-end" },
            { data: "credit", width: "7%", className: "text-end" },
        ];

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);

        // End DataTable

        //Acciones
        BtnSearch?.addEventListener("click", () => {
            start = getElement("[name=startDate]", this).value;
            end = getElement("[name=endDate]", this).value;
            project = getElement("[name=project]", this).value;
            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", () => {
            resetSelect2Ajax(getElement("[name=project]", this));
            getElement("[name=startDate]", this).value = "";
            getElement("[name=endDate]", this).value = "";
            project = "";
            start = "";
            end = "";
            T.ajax.reload();
        });
    }
}

window.customElements.define("journal-module", JournalModule, {
    extends: "section",
});
