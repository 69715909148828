import { CSRF, getData, getElement, resetSelect2Ajax } from "../../../helpers";
import { Dt } from "../../../plugins/datatable";

class LedgeInvoicesModule extends HTMLElement {
    connectedCallback() {
        this.purchases();
        this.sales();
        this.addEvents();
    }

    addEvents() {
        this.addEventListener("change", (e) => {
            if (e.target.name === "type") {
                const CntDtPurchases = getElement("cntDtPurchases", this);
                const CntDtSales = getElement("cntDtSales", this);
                const CntExportSales = getElement("cntSales", this);
                const CntExportPurchases = getElement("cntPurchases", this);

                if (e.target.value == "sales") {
                    CntDtPurchases.classList.add("d-none");
                    CntDtSales.classList.remove("d-none");
                    CntExportPurchases.classList.add("d-none");
                    CntExportSales.classList.remove("d-none");
                } else {
                    CntDtSales.classList.add("d-none");
                    CntDtPurchases.classList.remove("d-none");
                    CntExportSales.classList.add("d-none");
                    CntExportPurchases.classList.remove("d-none");
                }
            }
        });
    }

    async purchases() {
        //console.log(this);
        const DtElement = getElement("[data-table=dtPurchases]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);

        let project = "";
        let start = "";
        let end = "";
        let type = "";

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
        };

        configDt.ajax = {
            url: getData("ledgerinvoicesUrlDt"),
            type: "POST",
            data: function (data) {
                data.project = project;
                data.startDate = start;
                data.endDate = end;
                data.type = type;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };

        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "date", width: "7%" },
            { data: "date_invoice", width: "7%" },
            { data: "supplier_invoice" },
            { data: "cif" },
            { data: "supplier" },
            { data: "tax_base", className: "text-end" },
            { data: "tax", className: "text-end" },
            { data: "total_tax", className: "text-end" },
            { data: "withholdings", className: "text-end" },
            { data: "total_withholdings", className: "text-end" },
            { data: "total", className: "text-end" },
            { data: "project" },
        ];

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);

        // End DataTable

        //Acciones
        BtnSearch?.addEventListener("click", () => {
            type = getElement("[name=type]", this).value;
            if (type == "purchase") {
                start = getElement("[name=startDate]", this).value;
                end = getElement("[name=endDate]", this).value;
                project = getElement("[name=project]", this).value;
                T.ajax.reload();
            }
        });

        BtnReset?.addEventListener("click", () => {
            getElement("[name=startDate]", this).value = "";
            getElement("[name=endDate]", this).value = "";
            resetSelect2Ajax(getElement("[name=project]", this));
            project = "";
            start = "";
            end = "";
            type = "";
            T.ajax.reload();
        });
    }

    async sales() {
        //console.log(this);
        const DtElement = getElement("[data-table=dtSales]", this);
        const BtnSearch = getElement("[name=search]", this);
        const BtnReset = getElement("[name=reset]", this);

        let project = "";
        let start = "";
        let end = "";
        let type = "";

        // Start DataTable
        let configDt = {
            processing: true, // Procesar ajax
            serverSide: true, // Mejorar velocidad ajax registros por página
            searching: false,
        };

        configDt.ajax = {
            url: getData("ledgerSalesUrlDt"),
            type: "POST",
            data: function (data) {
                data.project = project;
                data.startDate = start;
                data.endDate = end;
                data.type = type;
            },
            beforeSend: function (request) {
                request.setRequestHeader("X-CSRF-TOKEN", CSRF);
            },
        };

        // Columnas del AJAX
        configDt.columns = [
            { data: "DT_RowIndex", width: "3%", className: "text-center" },
            { data: "date", width: "7%" },
            { data: "date_invoice", width: "7%" },
            { data: "ref" },
            { data: "cif" },
            { data: "customer" },
            { data: "tax_base", className: "text-end" },
            { data: "tax", className: "text-end" },
            { data: "total_tax", className: "text-end" },
            { data: "withholdings", className: "text-end" },
            { data: "total_withholdings", className: "text-end" },
            { data: "total", className: "text-end" },
            { data: "project" },
        ];

        const DtAp = new Dt(configDt);
        let T = await DtAp.dataTable(DtElement);

        // End DataTable

        //Acciones
        BtnSearch?.addEventListener("click", () => {
            start = getElement("[name=startDate]", this).value;
            end = getElement("[name=endDate]", this).value;
            project = getElement("[name=project]", this).value;
            type = getElement("[name=type]", this).value;
            T.ajax.reload();
        });

        BtnReset?.addEventListener("click", () => {
            getElement("[name=startDate]", this).value = "";
            getElement("[name=endDate]", this).value = "";
            resetSelect2Ajax(getElement("[name=project]", this));
            project = "";
            start = "";
            end = "";
            type = "";
            T.ajax.reload();
        });
    }
}

window.customElements.define("ledgerinvoices-module", LedgeInvoicesModule, {
    extends: "section",
});
